.my16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mx16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx32 {
  margin-left: 32px;
  margin-right: 32px;
}

.mr8 {
  margin-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

.mr32 {
  margin-right: 32px;
}

.ml-auto {
  margin-left: auto;
}

.ml16 {
  margin-left: 16px;
}

.ml32 {
  margin-left: 32px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb64 {
  margin-bottom: 64px;
}

.mb72 {
  margin-bottom: 72px;
}

.mt4 {
  margin-top: 4px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}
